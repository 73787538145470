import { motion } from "framer-motion"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

import Seo from "../../components/Seo"
import Layout from "../../components/Layout"
import ContactSection from "../../components/ContactSection"
import PageIntro2 from "../../components/PageIntro2"

function Project({ project }) {
  const { projectPageImage, logo, title, slug } = project.frontmatter

  return (
    <div className="mb-16">
      <Link
        to={"/projects/" + slug}
        className="grid grid-cols-2 md:grid-cols-6 gap-8 2xl:mx-28 py-8 hover:cursor-pointer"
      >
        <div className="col-span-full">
          <GatsbyImage
            image={projectPageImage.childImageSharp.gatsbyImageData}
            alt={`Screenshot of ${title}`}
          />
        </div>
        <img
          src={logo.publicURL}
          className="col-span-full md:col-span-2 w-40"
          alt={`Logo for ${title}`}
        />
        {/* <p  className="col-span-full md:col-start-3 md:col-end-7 text-gray-700">Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium sint, error quis illum commodi minus quasi ullam et magni eos laudantium eligendi iusto fugiat itaque eum cupiditate asperiores voluptatibus voluptatum odit aspernatur? Nemo dolor exercitationem ipsa alias eveniet? At suscipit minima ipsum deleniti earum! Laboriosam ducimus iusto amet dolorum? Ipsum.</p> */}
        <div className="col-span-full md:col-start-4 md:col-end-7 md:text-right">
          <p className="text-cyan-600 hover:underline text-lg ">
            Project details
            <FontAwesomeIcon icon={faChevronRight} className="ml-2 h-4" />
          </p>
        </div>
      </Link>
    </div>
  )
}

function ProjectsDisplay({ data }) {
  const projects = data.allMarkdownRemark.nodes

  return (
    <>
      {projects.map(
        (project, idx) =>
          project.frontmatter.showSection && (
            <Project project={project} key={idx} />
          )
      )}
    </>
  )
}

const ProjectsPage = ({ data }) => {
  return (
    <motion.div initial="initial" animate="animate" exit="exit">
      <Layout navBarType="white">
        <Seo title="Portfolio" />
        <PageIntro2 title="Our work" headline="Recent Work" height={"80vh"} />

        <motion.main
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            // transition: transition,
          }}
          className="container mx-auto pt-8"
        >
          <ProjectsDisplay data={data} />
        </motion.main>
        <ContactSection />
      </Layout>
    </motion.div>
  )
}

export default ProjectsPage

export const query = graphql`
  query ProjectPageData {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "project" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          services
          slug
          showSection
          title
          projectPageHeadline
          logo {
            publicURL
          }
          projectPageImage {
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
        }
      }
    }
  }
`
